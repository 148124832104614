import { useEffect, Fragment, lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { set as setAuth } from './store/authSlice';
import { set as setScreen } from './store/screenSlice';
import { set as setAvailability } from './store/availabilitySlice';
import axiosInstance from './utilities/axiosInstance';
import themes from './assets/themes';

let Home = lazy(() => import('./pages/home/home'));
let Categories = lazy(() => import('./pages/home/categories'));
let Brands = lazy(() => import('./pages/home/brands'));
let MultiProductPage = lazy(() => import('./pages/home/multiProductPage'));
let SingleProductPage = lazy(() => import('./pages/home/singleProductPage'));

// let Auth = lazy(() => import('./pages/auth/auth'));
// let SignIn = lazy(() => import('./pages/auth/signIn'));
// let VerifyLoginOTP = lazy(() => import('./pages/auth/verifyLoginOTP'));
// let SignUp = lazy(() => import('./pages/auth/signup'));
// let VerifyPhoneOTP = lazy(() => import('./pages/auth/verifyPhoneOTP'));

let Profile = lazy(() => import('./pages/user/profile'));
let WishList = lazy(() => import('./pages/user/wishList'));
let Addresses = lazy(() => import('./pages/user/addresses'));
let AddNewAddress = lazy(() => import('./pages/user/addNewAddress'));
let EditAddress = lazy(() => import('./pages/user/editAddress'));
let ProfileDetails = lazy(() => import('./pages/user/profileDetails'));
let ProfileDetail = lazy(() => import('./pages/user/profileDetail'));
let Update = lazy(() => import('./pages/user/update'));

let Partner = lazy(() => import('./pages/partner/partner'));
let PartnerSignup = lazy(() => import('./pages/partner/partner-signup'));

let TermsAndCondition = lazy(() => import('./pages/others/terms_and_condition'));
let ShippingPolicy = lazy(() => import('./pages/others/shipping-policy'));
let PrivacyPolicy = lazy(() => import('./pages/others/privacy-policy'));
let FAQs = lazy(() => import('./pages/others/faqs'));
let PartnerTermsAndConditions = lazy(() => import('./pages/others/partner-terms-and-conditions'));
let PartnerPrivacyPolicy = lazy(() => import('./pages/others/partner-privacy-policy'));
let CancellationRefundAndReturnPolicy = lazy(() => import('./pages/others/cancellation-return-and-refund-policy'));
let AboutUs = lazy(() => import('./pages/others/about-us'));

let Checkout = lazy(() => import('./pages/checkout/checkout'));
let Cart = lazy(() => import('./pages/checkout/cart/cart'));
let OrderCart = lazy(() => import('./pages/checkout/cart/orderCart'));
let SampleCart = lazy(() => import('./pages/checkout/cart/sampleCart'));
let ABSD = lazy(() => import('./pages/checkout/addBillingAndShippingDetails/absd'));
let AddBillingAndShippingDetails = lazy(() => import('./pages/checkout/addBillingAndShippingDetails/addBillingAndShippingDetails'));
let SelectAddress = lazy(() => import('./pages/checkout/addBillingAndShippingDetails/selectAddress'));
let AddNewAddress2 = lazy(() => import('./pages/checkout/addBillingAndShippingDetails/addNewAddress'));
let EditAddress2 = lazy(() => import('./pages/checkout/addBillingAndShippingDetails/editAddress'));
// let SelectGSTNumber = lazy(() => import('./pages/checkout/addBillingAndShippingDetails/selectGSTNumber'));
// let AD = lazy(() => import('./pages/checkout/ad/ad'));
// let ApplyDiscount = lazy(() => import('./pages/checkout/ad/applyDiscount'));
// let SelectDiscount = lazy(() => import('./pages/checkout/ad/selectDiscount'));
// let SelectPaymentMethod = lazy(() => import('./pages/checkout/selectPaymentMethod'));
// let ReviewOrder = lazy(() => import('./pages/checkout/reviewOrder'));
let NewReviewOrder = lazy(() => import('./pages/checkout/newReviewOrder'));
let OrderConfirmation = lazy(() => import('./pages/checkout/orderConfirmation'));
// let SelectPaymentMode = lazy(() => import('./pages/checkout/selectPaymentMode'));
let ProfileInformation = lazy(() => import('./pages/desktopView/profileInformation'));

let Orders = lazy(() => import('./pages/orders/orders'));
let O = lazy(() => import('./pages/orders/order/o'));
let Order = lazy(() => import('./pages/orders/order/order'));
let NewOrder = lazy(() => import('./pages/orders/order/newOrder'));
let Cancel = lazy(() => import('./pages/orders/order/cancel'));
let Return = lazy(() => import('./pages/orders/order/return'));
let ViewReturnProgress = lazy(() => import('./pages/orders/order/view-return-progress'));

let Profile2 = lazy(() => import('./pages/desktopView/profile2'));

let Footer = lazy(() => import('./containers/footer'));

// let SideBar = lazy(() => import('./containers/sideBar'));
let Auth = lazy(() => import('./containers/auth/auth'));
let CheckPincode = lazy(() => import('./containers/checkPincode'));
let Modal = lazy(() => import('./containers/modal'));
// let P = lazy(() => import('./pages/desktopView/p'));
let FAQs2 = lazy(() => import('./pages/others/faqs2'));
let TermsAndCondition2 = lazy(() => import('./pages/others/terms_and_condition2'));
let PrivacyPolicy2 = lazy(() => import('./pages/others/privacy-policy2'));

library.add(fas, far, fab);

function App() {
  const dispatch = useDispatch();
  const screen = useSelector((state) => state.screen);
  const theme = screen.theme;
  const auth = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  useEffect(() => {
    window.rupee = '₹';
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      dispatch(setScreen({ screenWidth: window.innerWidth }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    axiosInstance
      .get('/user')
      .then((res) => {
        dispatch(
          setAuth({
            loading: false,
            authenticated: true,
            user: res.data.data,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setAuth({
            loading: false,
            authenticated: false,
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.authenticated) {
      axiosInstance
        .get('/user/wishList')
        .then((res) => {
          dispatch(
            setAuth({
              wishList: res.data.data,
            })
          );
        })
        .catch((err) => { });

      axiosInstance
        .get('/user/cartCount')
        .then((res) => {
          dispatch(
            setAuth({
              cartCount: res.data.data,
            })
          );
        })
        .catch((err) => { });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.authenticated]);

  useEffect(() => {
    const pincode = localStorage.getItem('pincode');
    if (pincode) {
      axiosInstance
        .get(`/others/checkPincode/${pincode}`)
        .then((res) => {
          dispatch(setAvailability(res.data.data));
        })
        .catch((err) => { });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckPincode = () => {
    dispatch(setScreen({ showCheckPincode: !screen.showCheckPincode }));
  };

  return (
    <Fragment>
      {/* <Suspense fallback={<p>Loading...</p>}><SideBar /></Suspense> */}
      {screen.showAuth ? <Suspense fallback={<p>Loading...</p>}><Auth /></Suspense> : null}

      {screen.showCheckPincode ? <Suspense fallback={<p>Loading...</p>}>
        <Modal
          active={screen.showCheckPincode}
          onClick={() => handleCheckPincode()}
        >
          <CheckPincode onClick={() => handleCheckPincode()} />
        </Modal>
      </Suspense> : null}

      <div
        className="w-full min-w-full max-w-full min-h-screen relative"
        style={{
          backgroundColor: themes[theme].primaryBGColor,
        }}
      >
        <ToastContainer autoClose={3000} />

        <Routes>
          <Route path="/" element={<Suspense fallback={<p>Loading...</p>}><Home /></Suspense>} />
          <Route path="/categories" element={<Suspense fallback={<p>Loading...</p>}><Categories /></Suspense>} />
          <Route path="/brands" element={<Suspense fallback={<p>Loading...</p>}><Brands /></Suspense>} />
          <Route path="/products" element={<Suspense fallback={<p>Loading...</p>}><MultiProductPage /></Suspense>} />
          <Route path="/products/:id" element={<Suspense fallback={<p>Loading...</p>}><SingleProductPage /></Suspense>} />

          <Route path="/terms-and-conditions" element={<Suspense fallback={<p>Loading...</p>}><TermsAndCondition /></Suspense>} />
          <Route path="/shipping-policy" element={<Suspense fallback={<p>Loading...</p>}><ShippingPolicy /></Suspense>} />
          <Route path="/privacy-policy" element={<Suspense fallback={<p>Loading...</p>}><PrivacyPolicy /></Suspense>} />
          <Route path="/faqs" element={<Suspense fallback={<p>Loading...</p>}><FAQs /></Suspense>} />
          <Route path='/cancellation-Refund-and-Return-policy' element={<Suspense fallback={<p>Loading...</p>}><CancellationRefundAndReturnPolicy /></Suspense>} />
          <Route path="/about-us" element={<Suspense fallback={<p>Loading...</p>}><AboutUs /></Suspense>} />
          <Route
            path="/partner-terms-and-conditions"
            element={<Suspense fallback={<p>Loading...</p>}><PartnerTermsAndConditions /></Suspense>}
          />
          <Route
            path="/partner-privacy-policy"
            element={<Suspense fallback={<p>Loading...</p>}><PartnerPrivacyPolicy /></Suspense>}
          />

          {auth.authenticated ? <Fragment>

            <Route path="/profile" element={<Suspense fallback={<p>Loading...</p>}>
              {screen.screenWidth > 920 ? <Profile2 /> : <Profile />}
            </Suspense>}>
              {/* <Route path="" element={<Suspense fallback={<p>Loading...</p>}><P /></Suspense>} /> */}
              <Route path="" element={<Suspense fallback={<p>Loading...</p>}><ProfileInformation /></Suspense>}>
                <Route path="" element={<Suspense fallback={<p>Loading...</p>}><ProfileDetail /></Suspense>} />
                <Route path="update" element={<Suspense fallback={<p>Loading...</p>}><Update /></Suspense>} />
              </Route>

              <Route path='orders' element={<Suspense fallback={<p>Loading...</p>}><Orders /></Suspense>} />
              <Route path='orders/:id' element={<Suspense fallback={<p>Loading...</p>}><O /></Suspense>}>
                <Route path='' element={<Suspense fallback={<p>Loading...</p>}><NewOrder /></Suspense>} />
                {/* <Route path='cancel' element={<Suspense fallback={<p>Loading...</p>}><Cancel /></Suspense>} />
                <Route path='return/:id2' element={<Suspense fallback={<p>Loading...</p>}><Return /></Suspense>} />
                <Route path='view-return-progress/:id2' element={<Suspense fallback={<p>Loading...</p>}><ViewReturnProgress /></Suspense>} /> */}
              </Route>
              <Route path="wish-list" element={<Suspense fallback={<p>Loading...</p>}><WishList /></Suspense>} />
              <Route path="addresses" element={<Suspense fallback={<p>Loading...</p>}><Addresses /></Suspense>} />
              <Route
                path="addresses/add-new-address"
                element={<Suspense fallback={<p>Loading...</p>}><AddNewAddress /></Suspense>}
              />
              <Route path="addresses/:id" element={<Suspense fallback={<p>Loading...</p>}><EditAddress /></Suspense>} />
              <Route path="terms-and-conditions" element={<Suspense fallback={<p>Loading...</p>}><TermsAndCondition2 /></Suspense>} />
              <Route path="privacy-policy" element={<Suspense fallback={<p>Loading...</p>}><PrivacyPolicy2 /></Suspense>} />
              <Route path="faqs" element={<Suspense fallback={<p>Loading...</p>}><FAQs2 /></Suspense>} />
            </Route>

            <Route path="/wish-list" element={<Suspense fallback={<p>Loading...</p>}><WishList /></Suspense>} />
            <Route path="/addresses" element={<Suspense fallback={<p>Loading...</p>}><Addresses /></Suspense>} />
            <Route
              path="/addresses/add-new-address"
              element={<Suspense fallback={<p>Loading...</p>}><AddNewAddress /></Suspense>}
            />
            <Route path="/addresses/:id" element={<Suspense fallback={<p>Loading...</p>}><EditAddress /></Suspense>} />
            <Route path="/profile-details" element={<Suspense fallback={<p>Loading...</p>}><ProfileDetails /></Suspense>}>
              <Route path="" element={<Suspense fallback={<p>Loading...</p>}><ProfileDetail /></Suspense>} />
              <Route path="update" element={<Suspense fallback={<p>Loading...</p>}><Update /></Suspense>} />
            </Route>

            <Route path="/partner" element={<Suspense fallback={<p>Loading...</p>}><Partner /></Suspense>} />
            <Route path="/partner-signup" element={<Suspense fallback={<p>Loading...</p>}><PartnerSignup /></Suspense>} />

            <Route path="/checkout" element={<Suspense fallback={<p>Loading...</p>}><Checkout /></Suspense>}>
              <Route path="cart" element={<Suspense fallback={<p>Loading...</p>}><Cart /></Suspense>} >
                <Route path="order-cart" element={<Suspense fallback={<p>Loading...</p>}><OrderCart /></Suspense>} />
                <Route path="sample-cart" element={<Suspense fallback={<p>Loading...</p>}><SampleCart /></Suspense>} />
              </Route>
              <Route path="add-billing-and-shipping-details" element={<Suspense fallback={<p>Loading...</p>}><ABSD /></Suspense>}>
                <Route path="" element={<Suspense fallback={<p>Loading...</p>}><AddBillingAndShippingDetails /></Suspense>} />
                <Route path="select-address" element={<Suspense fallback={<p>Loading...</p>}><SelectAddress /></Suspense>} />
                <Route path="add-new-address" element={<Suspense fallback={<p>Loading...</p>}><AddNewAddress2 /></Suspense>} />
                <Route path=":id" element={<Suspense fallback={<p>Loading...</p>}><EditAddress2 /></Suspense>} />
                {/* <Route path="select-gst-number" element={<Suspense fallback={<p>Loading...</p>}><SelectGSTNumber /></Suspense>} /> */}
              </Route>
              {/* <Route path="apply-discount" element={<Suspense fallback={<p>Loading...</p>}><AD /></Suspense>}>
                <Route path="" element={<Suspense fallback={<p>Loading...</p>}><ApplyDiscount /></Suspense>} />
                <Route path="select-discount" element={<Suspense fallback={<p>Loading...</p>}><SelectDiscount /></Suspense>} />
              </Route>
              <Route path="select-payment-method" element={<Suspense fallback={<p>Loading...</p>}><SelectPaymentMethod /></Suspense>} /> */}
              <Route path="review-order" element={<Suspense fallback={<p>Loading...</p>}><NewReviewOrder /></Suspense>} />
              <Route path="order-confirmation" element={<Suspense fallback={<p>Loading...</p>}><OrderConfirmation /></Suspense>} />
              {/* <Route path="select-payment-mode" element={<Suspense fallback={<p>Loading...</p>}><SelectPaymentMode /></Suspense>} /> */}
            </Route>

            <Route path='orders' element={<Suspense fallback={<p>Loading...</p>}><Orders /></Suspense>} />
            <Route path='orders/:id' element={<Suspense fallback={<p>Loading...</p>}><O /></Suspense>}>
              <Route path='' element={<Suspense fallback={<p>Loading...</p>}><Order /></Suspense>} />
              <Route path='cancel' element={<Suspense fallback={<p>Loading...</p>}><Cancel /></Suspense>} />
              <Route path='return/:id2' element={<Suspense fallback={<p>Loading...</p>}><Return /></Suspense>} />
              <Route path='view-return-progress/:id2' element={<Suspense fallback={<p>Loading...</p>}><ViewReturnProgress /></Suspense>} />
            </Route>

          </Fragment> : null}

          {/* <Route path="/auth" element={<Suspense fallback={<p>Loading...</p>}><Auth /></Suspense>}>
            <Route path="sign-in" element={<Suspense fallback={<p>Loading...</p>}><SignIn /></Suspense>} />
            <Route path="verify-login-otp" element={<Suspense fallback={<p>Loading...</p>}><VerifyLoginOTP /></Suspense>} />
            <Route path="signup" element={<Suspense fallback={<p>Loading...</p>}><SignUp /></Suspense>} />
            <Route path="verify-phone-otp" element={<Suspense fallback={<p>Loading...</p>}><VerifyPhoneOTP /></Suspense>} />
          </Route> */}

          <Route path="*" element={<Navigate to={'/'} replace={true} />} />
        </Routes>
      </div>

      <Suspense fallback={<p>Loading...</p>}><Footer /></Suspense>
    </Fragment>
  );
}

export default App;
